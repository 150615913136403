
import './App.css';
import { Flex, Image, Link, Text } from '@chakra-ui/react';
import { BsArrowDownCircle, BsInstagram, BsLinkedin } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';


function App() {

  const scrollToEl = (id) => {
    const element = document.getElementById(id);
    window.scrollTo({
      top: element.offsetTop,
      behavior: 'smooth',
    });
  };

  const urlInstagram = () => {
		const url = "https://www.instagram.com/jonesagencia/";
		window.open(url);
  };
  const urlLinkedin = () => {
		const url = "https://www.linkedin.com/company/jonesagencia";
		window.open(url);
  };

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className="App" fontFamily={'GothamNarrowBook'} id="initial">
      <Flex minHeight={'100vh'} bg={'#401b52'} alignItems={'center'} justifyContent={'center'} overflow={'hidden'}>
        {isMobile ? (
          <Image src={'assets/images/logo.png'} width={'200%'} ml={'12vw'} />
          // <Image src={'assets/images/logo.png'} maxWidth={'90%'} />
        ) : (
          <Image src={'assets/images/logo.png'} maxWidth={'90%'} />
        )}
      </Flex>

      <BsArrowDownCircle
        style={{
          position:'absolute',
          right: "30px",
          marginTop: "-70px"
        }}
        cursor={'pointer'}
        color={'#FFFFFF'}
        fontSize={40}
        onClick={() => scrollToEl("video")}
        className='btnHover'
      />

      <Flex width={"100%"} minH={'100vh'} bg={'#401b52'} alignItems={'center'} justifyContent={'center'} id="video">
        <video autoplay="" playsinline="" muted loop width={'100%'} id="videoJones">
          <source
            src="assets/video/jones_lancamento.mp4"
            type="video/mp4"
            autoplay="" muted loop data-object-fit="cover"
          />
        </video>
      </Flex>
      <BsArrowDownCircle
        style={{
          position:'absolute',
        right: "30px",
        marginTop: "-70px"
        }}
        cursor={'pointer'}
        color={'#FFFFFF'}
        fontSize={40}
        onClick={() => scrollToEl("footer")}
        className='btnHover hiddenMobile'
      />


      <Flex padding={30} minH={'100vh'} gap={50} bg={'#401b52'} color={'#FFFFFF'} alignItems={'center'} justifyContent={'center'} id="footer" className='footer'>

        <Image src={'assets/images/logo-jones.png'} maxWidth={'30vw'} ml={'3vw'} />
        {/* {isMobile ? (
          <Image src={'assets/images/logo.png'} maxWidth={'100%'} />
        ) : (
          <Image src={'assets/images/logo-jones.png'} maxWidth={'30vw'} ml={'3vw'} />
        )} */}

        <Flex direction={'column'}>
          <Text fontFamily={'GothamNarrowMedium'} mb={30} lineHeight={'1em'} className='titleFooter'>Creativity has a name. And a last name.</Text>
          <Text fontFamily={'GothamNarrowBook'} mb={15}>Jones is a communication agency that entertains, builds and engages communities and drives business results.</Text>
          <Text fontFamily={'GothamNarrowBook'} mb={15}>With its own signature and strong creative identity, we have recognition and experience in the most different projects for major global brands.</Text>
          <Text fontFamily={'GothamNarrowBook'}>Any questions? Contact us.</Text>

          <Flex gap={15} alignItems={'center'} fontSize={30} mt={30}>
            <Link onClick={urlInstagram} cursor={'pointer'} color="#FFFFFF" className='btnHover'><BsInstagram /></Link>
            <Link onClick={urlLinkedin} cursor={'pointer'} color="#FFFFFF" className='btnHover'><BsLinkedin /></Link>
          </Flex>
        </Flex>

        {/* <Divider bg={'#000000'} display={['none', null, 'block']} width={10} opacity={".1"} height={'85vh'} className='dividerFooter' /> */}

      </Flex>
    </div>
  );
}

export default App;
